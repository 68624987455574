import { getBackend } from "@/sites";
import axios from "axios";
import { getLanguageFromPath } from "../hooks/useLanguage";


export function createApiInstance(backendHost: string | undefined) {
    const apiInstance = axios.create({
        baseURL: backendHost,
        withCredentials: true,
        timeout: 50000,
    });

    apiInstance.defaults.headers.post["Content-Type"] = "application/json";

    return apiInstance;
}

export const apiInstance = createApiInstance(process.env.NEXT_PUBLIC_BACKEND_HOST);
apiInstance.interceptors.request.use(config => {
    const lang = getLanguageFromPath(window.location.pathname);
    config.baseURL = getBackend(lang);
    return config;
}, error => {
    return Promise.reject(error);
});

export function handleErrors(error: unknown): Error {
    if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorBody = error.response.data;
                return new Error(errorBody?.message || "Error was thrown but 'message' property not in response body", { cause: error });
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                reportError(error);
                return error;
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
                return error;
            }
        } else {
            // Just a stock error
            reportError(error);
        }
        console.error("Error occured: ", error);
        return error;
    }
    return new Error("Error thrown is not of type error!");
}
